export const PRIVATE = {
  GLOW_FEATURES: 'Private - Glow Features',
  SHINE_FEATURES: 'Private - Shine Features',
  LUMINATE_FEATURES: 'Private - Luminate Features',
  ADD_NEW_PLATFORM: 'Private - Add New Platform',
  DASHBOARD: 'Private - Dashboard',
  REMINDERS: 'Private - Reminders',
  INTERNAL_NOTE: 'Private - Internal Note',
  ATS: {
    BOARD: 'Private - ATS Board',
    BOARD_CARD: 'Private - ATS Board Card',
    JOBS: 'Private - ATS Jobs',
    JOB: 'Private - ATS Job',
    JOB_CANDIDATE: 'Private - ATS Job Candidate',
    TALENT_POOL: 'Private - ATS Talent Pool',
  },
  STAFF: {
    MENU_HEADER: 'Private - STAFF Menu Header',
    EMPLOYEES: 'Private - STAFF Employees',
    EMPLOYEES_FILES: 'Private - STAFF Employees Files',
    HOUSES: 'Private - STAFF Houses',
    HOUSES_FILES: 'Private - STAFF Houses Files',
    PROJECTS: 'Private - STAFF Projects',
    PROJECTS_FILES: 'Private - STAFF Projects Files',
    CLIENTS: 'Private - STAFF Client',
    CLIENTS_FILES: 'Private - STAFF Client Files',
  },
  TEMPLATE: {
    EMAIL: 'Private - TEMPLATE Email',
    WORKFLOW: 'Private - TEMPLATE Workflow',
  },
  SETTINGS: {
    COMPANY: 'Private - SETTINGS Company',
    TAGS: 'Private - SETTINGS Company tags',
    USER_MANAGEMENT: 'Private - SETTINGS User Management',
    ACCOUNT: 'Private - SETTINGS Account',

    JOBS: 'Private - Jobs Settings',
    EMPLOYEES: 'Private - Employees Settings',
    HOUSES: 'Private - Houses Settings',
    CLIENTS: 'Private - Clients Settings',
    PROJECTS: 'Private - Projects Settings',
  },
}

export const PUBLIC = 'Public'

export const ACTIONS = {
  READ: 'read',
  WRITE: 'write',
  DUPLICATE: 'duplicate',
  DELETE: 'delete',
  UPDATE: 'update',

  JOBS: {
    MANAGE_JOB: 'manageJob',
    MANAGE_CANDIDATES: 'manageCandidates',
    MANAGE_CANDIDATE: 'manageCandidate',
    VIEW_CANDIDATE: 'viewCandidate',
    MANAGE_JOB_STAGE: 'manageJobStage',
  },
}

export const MANAGE = 'manage'
export const ALL = 'all'

export const _ = undefined
