import { PRIVATE } from '@/plugins/acl/const'

const apps = [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Invoice ——————————————————
  //

  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— ATS ——————————————————
  //
  {
    path: '/ats/board',
    name: 'apps-ats-board',
    meta: {
      layout: 'content',
      resource: PRIVATE.ATS.BOARD,
    },
    component: () => import('@/views/apps/ats/board/BoardView.vue'),
  },
  {
    path: '/ats/job',
    name: 'apps-ats-jobs-list',
    component: () => import('@/views/apps/ats/jobs/job-list/JobList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.ATS.JOBS,
    },
  },
  {
    path: '/ats/job/:id',
    name: 'apps-ats-job-view',
    component: () => import('@/views/apps/ats/jobs/job-view/JobView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.ATS.JOB,
      breadCrumb(context) {
        return [
          {
            text: 'jobs',
            useI18n: true,
            to: { name: 'apps-ats-jobs-list' },
          },
          {
            text: context.jobName,
          },
        ]
      },
    },
  },
  {
    path: '/ats/job/:jobId/candidate/:candidateId',
    name: 'apps-ats-candidate-view',
    component: () => import('@/views/apps/ats/jobs/candidate-view/CandidateView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.ATS.JOB_CANDIDATE,
      breadCrumb(context) {
        return [
          {
            text: 'jobs',
            useI18n: true,
            to: { name: 'apps-ats-jobs-list' },
          },
          {
            text: context.jobName,
            useI18n: false,
            to: {
              name: 'apps-ats-job-view',
              params: {
                id: context.jobId,
              },
            },
          },
          {
            text: context.candidateName,
            useI18n: false,
          },
        ]
      },
    },
  },

  {
    path: '/apply/:id',
    name: 'apps-ats-job-apply-form',
    component: () => import('@/views/apps/ats/jobs/ApplyForm.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false,
    },
  },

  {
    path: '/ats/talent-pool',
    name: 'apps-ats-talent-pool-list',
    component: () => import('@/views/apps/ats/talent-pool/talent-pool-list/TalentPoolList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.ATS.TALENT_POOL,
    },
  },
  {
    path: '/ats/talent-pool/:id',
    name: 'apps-ats-talent-pool-entry-view',
    component: () => import('@/views/apps/ats/talent-pool/talent-pool-entry-view/TalentPoolEntryView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.ATS.TALENT_POOL,
      breadCrumb(context) {
        return [
          {
            text: 'talentPool',
            useI18n: true,
            to: { name: 'apps-ats-talent-pool-list' },
          },
          {
            text: context.talentPoolEntryName,
            useI18n: false,
          },
        ]
      },
    },
  },
  {
    path: '/talent-pool-sign-up',
    name: 'apps-ats-talent-pool-sign-up-form',
    component: () => import('@/views/apps/ats/talent-pool/TalentPoolSignUp.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: false,
    },
  },

  //* ——— Staff ——————————————————
  //

  {
    path: '/staff/employee',
    name: 'apps-employee-list',
    component: () => import('@/views/apps/staff/employees/employee-list/EmployeeList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.EMPLOYEES,
    },
  },

  {
    path: '/staff/employee/:id',
    name: 'apps-employee-view',
    component: () => import('@/views/apps/staff/employees/employee-view/EmployeeView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.EMPLOYEES,
      breadCrumb(context) {
        return [
          {
            text: 'employees',
            useI18n: true,
            to: { name: 'apps-employee-list' },
          },
          {
            text: context.employeeName,
            useI18n: false,
          },
        ]
      },
    },
  },

  {
    path: '/staff/house',
    name: 'apps-houses-list',
    component: () => import('@/views/apps/staff/houses/house-list/HouseList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.HOUSES,
    },
  },

  {
    path: '/staff/house/:id',
    name: 'apps-house-view',
    component: () => import('@/views/apps/staff/houses/house-view/HouseView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.HOUSES,
      breadCrumb(context) {
        return [
          {
            text: 'houses',
            useI18n: true,
            to: { name: 'apps-houses-list' },
          },
          {
            text: context.houseName,
            useI18n: false,
          },
        ]
      },
    },
  },

  {
    path: '/staff/project',
    name: 'apps-project-list',
    component: () => import('@/views/apps/staff/projects/project-list/ProjectList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.PROJECTS,
    },
  },
  {
    path: '/staff/project/:id',
    name: 'apps-project-view',
    component: () => import('@/views/apps/staff/projects/project-view/ProjectView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.PROJECTS,
      breadCrumb(context) {
        return [
          {
            text: 'projects',
            useI18n: true,
            to: { name: 'apps-project-list' },
          },
          {
            text: context.projectName,
            useI18n: false,
          },
        ]
      },
    },
  },
  {
    path: '/staff/project/position/:id',
    name: 'apps-project-position-view',
    component: () => import('@/views/apps/staff/projects/project-view/project-position-view/PositionView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.PROJECTS,
      breadCrumb(context) {
        return [
          {
            text: 'projects',
            useI18n: true,
            to: { name: 'apps-project-list' },
          },
          {
            text: context.projectName,
            useI18n: false,
            to: {
              name: 'apps-project-view',
              params: {
                id: context.projectId,
              },
            },
          },
          {
            text: context.positionName,
            useI18n: false,
          },
        ]
      },
    },
  },
  {
    path: '/staff/house/room/:id',
    name: 'apps-house-room-view',
    component: () => import('@/views/apps/staff/houses/house-view/house-room-view/RoomView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.PROJECTS,
      breadCrumb(context) {
        return [
          {
            text: 'houses',
            useI18n: true,
            to: { name: 'apps-houses-list' },
          },
          {
            text: context.houseName,
            useI18n: false,
            to: {
              name: 'apps-house-view',
              params: {
                id: context.houseId,
              },
            },
          },
          {
            text: context.roomName,
            useI18n: false,
          },
        ]
      },
    },
  },
  {
    path: '/staff/client',
    name: 'apps-client-list',
    component: () => import('@/views/apps/staff/clients/client-list/ClientList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.CLIENTS,
    },
  },
  {
    path: '/staff/client/:id',
    name: 'apps-client-view',
    component: () => import('@/views/apps/staff/clients/client-view/ClientView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.STAFF.CLIENTS,
      breadCrumb(context) {
        return [
          {
            text: 'clients',
            useI18n: true,
            to: { name: 'apps-client-list' },
          },
          {
            text: context.clientName,
            useI18n: false,
          },
        ]
      },
    },
  },

  //
  //* ——— Templates ——————————————————
  //

  {
    path: '/templates/documents',
    name: 'apps-document-templates-list',
    component: () => import('@/views/apps/templates/document/document-list/DocumentList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SHINE_FEATURES,
    },
  },
  {
    path: '/templates/document/:id',
    name: 'apps-document-templates-view',
    component: () => import('@/views/apps/templates/document/document-view/DocumentView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SHINE_FEATURES,
      navActiveLink: 'apps-document-templates-list',
      breadCrumb(context) {
        return [
          {
            text: 'document',
            useI18n: true,
            to: { name: 'apps-document-templates-list' },
          },
          {
            text: context.documentTemplateName,
            useI18n: false,
          },
        ]
      },
    },
  },

  {
    path: '/templates/workflow',
    name: 'apps-workflow-templates-list',
    component: () => import('@/views/apps/templates/workflow/workflow-list/WorkflowList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.TEMPLATE.WORKFLOW,
    },
  },
  {
    path: '/templates/workflow/view/:id',
    name: 'apps-workflow-template-view',
    component: () => import('@/views/apps/templates/workflow/workflow-view/WorkflowView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.TEMPLATE.WORKFLOW,
      breadCrumb(context) {
        return [
          {
            text: 'workflow',
            useI18n: true,
            to: { name: 'apps-workflow-templates-list' },
          },
          {
            text: context.workflowTemplateName,
            useI18n: false,
          },
        ]
      },
    },
  },

  {
    path: '/templates/email',
    name: 'apps-email-templates-list',
    component: () => import('@/views/apps/templates/email/email-list/EmailList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.TEMPLATE.EMAIL,
    },
  },
  {
    path: '/templates/email/:id',
    name: 'apps-email-template-view',
    component: () => import('@/views/apps/templates/email/email-view/EmailView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.TEMPLATE.EMAIL,
      breadCrumb(context) {
        return [
          {
            text: 'email',
            useI18n: true,
            to: { name: 'apps-email-templates-list' },
          },
          {
            text: context.emailTemplateName,
            useI18n: false,
          },
        ]
      },
    },
  },
  {
    path: '/templates/email/default/:action',
    name: 'apps-default-email-template-view',
    component: () => import('@/views/apps/templates/email/default-email-view/DefaultEmailView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.TEMPLATE.EMAIL,
      breadCrumb(context) {
        return [
          {
            text: 'email',
            useI18n: true,
            to: { name: 'apps-email-templates-list' },
          },
          {
            text: context.emailTemplateName,
            useI18n: false,
          },
        ]
      },
    },
  },

  //
  //* ——— Settings ——————————————————
  //
  {
    path: '/settings/company/details',
    name: 'apps-company-view',
    component: () => import('@/views/pages/settings/company/company-view/CompanyView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SETTINGS.COMPANY,
    },
  },
  {
    path: '/settings/company/user-management',
    name: 'apps-user-list',
    component: () => import('@/views/pages/settings/company/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SETTINGS.USER_MANAGEMENT,
    },
  },
  {
    path: '/settings/company/user-management/user/:id',
    name: 'apps-user-view',
    component: () => import('@/views/pages/settings/company/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SETTINGS.USER_MANAGEMENT,
      breadCrumb(context) {
        return [
          {
            text: 'userManagement',
            useI18n: true,
            to: { name: 'apps-user-list' },
          },
          {
            text: context.recruiterName,
            useI18n: false,
          },
        ]
      },
    },
  },
  {
    path: '/settings/job',
    name: 'page-jobs-settings',
    component: () => import('@/views/pages/settings/job/JobsSettingsView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SETTINGS.JOBS,
    },
  },
  {
    path: '/settings/employee',
    name: 'page-employees-settings',
    component: () => import('@/views/pages/settings/employee/EmployeeSettingsView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SETTINGS.EMPLOYEES,
    },
  },
  {
    path: '/settings/house',
    name: 'page-houses-settings',
    component: () => import('@/views/pages/settings/house/HouseSettingsView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SETTINGS.HOUSES,
    },
  },
  {
    path: '/settings/client',
    name: 'page-clients-settings',
    component: () => import('@/views/pages/settings/client/ClientSettingsView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SETTINGS.CLIENTS,
    },
  },
  {
    path: '/settings/project',
    name: 'page-projects-settings',
    component: () => import('@/views/pages/settings/project/ProjectSettingsView.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.SETTINGS.PROJECTS,
    },
  },

  //
  //* ——— Chat ——————————————————
  //

  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default apps
